<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Septiembre 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!--Snackbar de elemento exitoso-->
    <v-snackbar v-model="snackbarValue" :color="snackbarColor ? 'success' : 'error'" timeout="2000">
        {{ snackbarMessage }}
    </v-snackbar>
    
    <!-- FILTROS -->
    <section class= "d-grid">
      <section class="d-flex">
        <!--Proceso a filtrar-->
        <FilterCustomized
        v-model="buscar.idProceso" 
        :items="listaBusqueda.proceso" item-value="id" item-text="name" label="Proceso" :style="{ maxWidth: '33%' }"
        @update:search-input= "(value) =>llenarLista('proceso',listaBusqueda.proceso,value,'idProceso','nombre',false)"
        @input= "filtrarItems()"
        />
        <!--Grupo a filtrar-->
        <FilterCustomized
        v-model="buscar.idGrupoProceso" 
        :items="listaBusqueda.grupoProceso" item-value="id" item-text="name" label="Grupo" :style="{ maxWidth: '33%' }"
        @update:search-input= "(value) =>llenarLista('grupo-proceso',listaBusqueda.grupoProceso,value,'idGrupoProceso','nombre',false)"
        @input= "filtrarItems()"
        />
        <!--Ubicacion a filtrar-->
        <FilterCustomized
        v-model="buscar.idUbicacionColaborador" 
        :items="listaBusqueda.ubicacionColaborador" item-value="id" item-text="name" label="Ubicacion" :style="{ maxWidth: '34%' }"
        @update:search-input= "(value) =>llenarLista('ubicacion',listaBusqueda.ubicacionColaborador,value,'idUbicacionColaborador','nombre',false)"
        @input= "filtrarItems()"
        />
      </section>
      <section class="d-flex mt-3">
        <!--Nivel Jerarquicor-->
        <FilterCustomized
        v-model="buscar.idNivelJerarquico" 
        :items="listaBusqueda.nivelJerarquico" item-value="id" item-text="name" label="Nivel Jerarquico" :style="{ maxWidth: '33%' }"
        @update:search-input= "(value) =>llenarLista('nivel-jerarquico',listaBusqueda.nivelJerarquico,value,'idNivelJerarquico','nombre',false)"
        @input= "filtrarItems()"
        />
        <!--Lider-->
        <FilterCustomized
        v-model="buscar.idEvaluador" 
        :items="listaBusqueda.evaluador" item-value="id" item-text="name" label="Líder" :style="{ maxWidth: '32%' }"
        @update:search-input= "() =>llenarListaDistincto('lider-grupo',listaBusqueda.evaluador,'idColaborador','nombre',false)"
        @input= "filtrarItems()"
        />
        <!-- Este botón cambia de lider las evaluaciones seleccionadas -->
        <div>
          <v-tooltip location="left" color="success" :open-on-focus="false">
            <template v-slot:activator="{ props }">
              <v-btn class="mr-2" color="success" v-bind="props"
                @mousedown.prevent="dialogoCambiarLider = true">
                <v-icon>
                  upgrade
                </v-icon> 
                Cambiar líder
              </v-btn>
            </template>
            <span>Cambia los líderes de los registros seleccionados</span>
          </v-tooltip>
        </div>
        <!-- Este botón reemplaza de manera masiva un lider por otro 
        <div>
          <v-tooltip location="left" color="success" :open-on-focus="false">
            <template v-slot:activator="{ props }">
              <v-btn color="success" v-bind="props"
                @mousedown.prevent="dialogoReemplazarLider = true">
                <v-icon>
                  sync_alt
                </v-icon> 
                Reemplazar Líder
              </v-btn>
            </template>
            <span>Reemplaza de manera masiva un líder por otro</span>
          </v-tooltip>
        </div>-->
      </section>
    </section>

    <!-- Dialogo para guardar de manera masiva las relaciones de líderes con procesos -->
    <v-dialog v-model="dialogoCambiarLider" transition="dialog-bottom-transition" max-width="35rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> Cambiar Lider a selección </span>
        </v-card-title>
        <!-- Formulario para registrar una Item -->
        <v-card-text class="pt-5">
          <v-form ref="itemForm">
              <!--Nuevo evaluador / Líder -->
              <FilterCustomized
              v-model="idNuevoEvaluador" class="mt-4 mb-4"
              :items="listaGuardado.nuevoEvaluador" item-value="id" item-text="name" label="Lider" :rules="rules.required" :hide-details="false"
              @update:search-input= "(value) =>llenarLista('colaborador',listaGuardado.nuevoEvaluador,value,'idColaborador','nombre',false)"
              />
          </v-form>
          <!-- Botones del formulario -->
          <div class="d-flex justify-end">
          <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
          <v-btn :disabled="!formValid" text :color="!formValid ? 'grey' : 'success'"
            @click="guardarItems()">Cambiar
          </v-btn>
        </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" v-model="selected"  show-select
      :headers="tabla.headers"
      :items="tabla.items"
      item-key="idEvaluadorGrupo"
      :single-select="false"
      :loading="tabla.loading" 
      :footer-props="tabla.footerProps"
      :items-per-page="tabla.itemsPerPage"
      :page="tabla.page" 
      :server-items-length="tabla.totalPage" height="60vh"
      @update:items-per-page="(i) => tabla.itemsPerPage = i" 
      @update:page="(p) => tabla.page = p"
      >

      <template v-slot:[`item.estado`]="{ item }">
        <v-icon :color="item.eliminado === false ? 'success' : 'error'">
          {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
        </v-icon>
          {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'grey'">
          <template v-slot:activator="{ props }">
            <v-btn icon medium v-bind="props" class="ms-2 me-2" @click="item.eliminado === false && abrirDialogoEditar(item)">
              <v-icon medium :color="item.eliminado === false ? 'orange' : 'grey'">border_color</v-icon>
            </v-btn>
          </template>
        <span>{{ item.eliminado === false ? 'Editar' : `${nombreItem} inactivo` }}</span>
        </v-tooltip>

        <v-tooltip v-if="!sincEnterprise.some(x => x === enterprise.code)" bottom :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ props }">
            <v-btn icon medium class="ms-2 me-2" v-bind="props" @click="abrirDialogoEstado(item)">
              <v-icon medium :color="item.eliminado === false ? 'error' : 'success'">{{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg'}}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
      
    </v-data-table>

  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterCustomized from "@/components/human-talent/FilterCustomized.vue"
import { required } from "@/validators/validations";


export default {
  name:"LiderGrupo",
  components: {
    FilterCustomized
  },
  data(){
    return{
      ruta:"msa-hr/api/lider-grupo",
      lastmodifiedby:"",
      sincEnterprise:[1],
      dialogoCambiarLider: false,
      dialogoReemplazarLider: false,
      snackbarValue: false,
      snackbarMessage: '',
      snackbarColor:false,
      nombreItem : 'Relación de Lider con Grupo',
      idNuevoEvaluador: null,
      singleSelect:false,
      rules: {
        required: [required]
      },
      buscar:{
        idGrupoProceso:'',
        idProceso:'',
        idUbicacionColaborador:'',
        idNivelJerarquico:'',
        idEvaluador:'',
      },
      listaBusqueda:{
        grupoProceso:[],
        proceso:[],
        ubicacionColaborador:[],
        nivelJerarquico:[],
        evaluador:[],
      },
      listaGuardado:{
        nuevoEvaluador:[],
        antiguoEvaluador:[],
      },
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "PROCESO", width: "10%", sortable: false, align: "center", value: "grupoProceso.proceso.nombre"},
          { text: "GRUPO", width: "20%", sortable: false, align: "center", value: "grupoProceso.nombre" },
          { text: "UBICACION", width: "20%", sortable: false, align: "center", value: "ubicacionColaborador.nombre" },
          { text: "NIVEL JERARQUICO", width: "10%", sortable: false, align: "center", value: "nivelJerarquico.nombre" },
          { text: "LIDER", width: "10%", sortable: false, align: "center", value: "evaluador.nombre" },
          { text: "ESTADO", width: "10%", sortable: false, align: "center", value: "estado" },
          { text: "ACCIONES", width: "10%", sortable: false, align: "center", value: "acciones"},
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      selected: [],
    }
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    formValid() {
      return !!this.idNuevoEvaluador;
    },
    estado() {
      return !this.crearItem.eliminado
        ? `¿Desea activar ${this.nombreItem}?`
        : `¿Desea inactivar ${this.nombreItem}?`;
    },
  },
  methods: {
      /**
     * Método que obtiene y lista los items de la base de datos utilizando criterios de paginación y filtrado.
     * La respuesta obtenida se utiliza para actualizar la tabla de items en el frontend,
     * asignando la lista de items a 'tabla.items' y la cantidad total de items a 'tabla.totalPage'.
     */
    async listarItems() {
      this.tabla.loading = true;
      try{
          const response = await this.$http
          .get(`${this.ruta}/listar`, {
            params: {
              nombre: `${this.buscar.nombre}`,
              idEmpresa: this.enterprise.code,
              idProceso: this.buscar.idProceso,
              idGrupoProceso:this.buscar.idGrupoProceso,
              idUbicacionColaborador:this.buscar.idUbicacionColaborador,
              idNivelJerarquico:this.buscar.idNivelJerarquico,
              idEvaluador:this.buscar.idEvaluador,
              page: this.tabla.page - 1,
              size: this.tabla.itemsPerPage,
            },
          })
      
        this.tabla.items = response.data.content;
        this.tabla.totalPage = response.data.totalElements;
        this.tabla.loading = false;

      }catch(error) {
        console.log(error);
        this.tabla.loading = false;
      }
    },

    //Método para guardar todos los items con un mismo lider
    async guardarItems(){
      try{
        if(this.selected.length > 0){

          const listaLiderGrupo = this.selected.map(item => {
            return {
              idEvaluadorGrupo: item.idEvaluadorGrupo,
              idColaborador: this.idNuevoEvaluador,
              idEmpresa: this.enterprise.code,
              idGrupoProceso: item.grupoProceso.idGrupoProceso,
              idUbicacionColaborador: item.ubicacionColaborador.idUbicacionColaborador,
              idNivelJerarquico: item.nivelJerarquico.idNivelJerarquico,
              createdby:this.auth.username.toUpperCase(),
              lastmodifiedby:this.auth.username.toUpperCase()
            }
          })
 
          await this.$http.post(`${this.ruta}/guardar-lista`,listaLiderGrupo)
          await this.listarItems()
          this.snackbarValue = true;
          this.snackbarMessage = 'Se actualizaron registros seleccionados';
          this.snackbarColor = true;
          this.clear();
        }
      } catch(error){
        console.log(error)
        this.snackbarValue = true;
        this.snackbarMessage = error.message;
        this.snackbarColor = false;
      }

    },
    //Manejo de solicitudes al BackEnd
    async listar(nombre, Url, soloActivos) {
      let lista = await this.$http.get(`msa-hr/api/${Url}/listar`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombre,
          nombreCompleto: nombre,
          eliminado: soloActivos ? !soloActivos : '',
          size: 100
        },
      });
      return lista.data.content ? lista.data.content : [];
    },

    //Llenado de una lista para filtro
    async llenarLista(Url,listado,nombreBusqueda, nombreId, nombreItem = "nombre", soloActivos) {
      //Trae la lista desde el back
      const lista = await this.listar(nombreBusqueda, Url,soloActivos);
      // Vaciar el listado original
      listado.length = 0;
      // Llenar el listado con los nuevos elementos
      lista.forEach(item => {
        listado.push({
          name: `${item[nombreItem].trim()}${item.apellidos? ` ${item.apellidos.trim()}` : ''}` ,
          id: item[nombreId]
        });
      });
    },
    //Lista de lideres asignados
    async llenarListaDistincto(Url,listado, nombreId, nombreItem = "nombre", soloActivos) {
      //Trae la lista desde el back
      let lista = await this.$http.get(`msa-hr/api/${Url}/listar-distinto`, {
        params: {
          idEmpresa: this.enterprise.code,
          eliminado: soloActivos ? !soloActivos : '',
          size: 200
        },
      });
      lista = lista.data.content ? lista.data.content : [];
      // Vaciar el listado original
      listado.length = 0;
      // Llenar el listado con los nuevos elementos
      lista.forEach(item => {
        listado.push({
          name: `${item[nombreItem].trim()}${item.apellidos? ` ${item.apellidos.trim()}` : ''}` ,
          id: item[nombreId]
        });
      });
    },
    async filtrarItems(){
      this.tabla.page = 1;
      this.listarItems();
    },
    //Restablece valores de formularios y vista
    clear(){
      this.dialogoCambiarLider = false;
      this.dialogoReemplazarLider = false;
      this.idNuevoEvaluador = null;
      this.selected = [];
      if (this.$refs.itemForm) {
        this.$refs.itemForm.resetValidation();
      }
    }
  },
  watch: {
    // Escucha los cambios en la página actual de la tabla de items.
    // Si cambia, llama a la función para listar las items.
    "tabla.page": function () {
      this.listarItems();
    },
    // Escucha cambios en la cantidad de ítems por página en la tabla de items.
    // Si cambia, reinicia la página actual a 1 y lista los items.
    "tabla.itemsPerPage": function () {
      this.tabla.page = 1;
      this.listarItems();
    },
  },
  mounted() {
    this.listarItems();
    this.llenarLista('grupo-proceso',this.listaBusqueda.grupoProceso,'','idGrupoProceso','nombre',false);
    this.llenarLista('ubicacion',this.listaBusqueda.ubicacionColaborador,'','idUbicacionColaborador','nombre',false);
    this.llenarLista('proceso',this.listaBusqueda.proceso,'','idProceso','nombre',false);
    this.llenarLista('nivel-jerarquico',this.listaBusqueda.nivelJerarquico,'','idNivelJerarquico','nombre',false);
    this.llenarListaDistincto('lider-grupo',this.listaBusqueda.evaluador,'idColaborador','nombre',false);
    this.lastmodifiedby = this.auth.username.toUpperCase();
  },
}
</script>

<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>